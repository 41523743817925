import { FormEvent, useState } from "react"
import styled from "styled-components"

import { SectionAutocall } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseAlertDialog/SectionAutocall"
import { SectionCallAssist } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseAlertDialog/SectionCallAssist"
import { SectionFlashAndSound } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseAlertDialog/SectionFlashAndSound"
import {
  usePostTrackNoiseSettingsManageDialogCancelledEvent,
  usePostTrackNoiseSettingsManageDialogSavedEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { usePatchOrganization } from "src/data/organizations/queries/organizationQueries"
import {
  createOrganizationRequest,
  createProfileRequest,
} from "src/data/profileSettings/noiseAlertSettings"
import {
  TNoiseAlertSettings,
  TNoiseAlertVariant,
} from "src/data/profileSettings/noiseAlertTypes"
import { usePatchNoiseMonitoringPreset } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useIsValueDirty } from "src/hooks/useIsValueDirty"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { DiscardChangesDialog } from "src/ui/Dialog/DiscardChangesDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDividerCss } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

import { SectionSms } from "./SectionSms"

const FORM_ID = "noise-alert-form"

type NoiseAlertDialogProps = {
  onClose: () => void
  settings: TNoiseAlertSettings
  variant: TNoiseAlertVariant
  open: boolean
}
export function NoiseAlertsDialog({ open, ...props }: NoiseAlertDialogProps) {
  if (!open) return null
  return <NoiseAlertsDialogOpen {...props} open={true} />
}

function NoiseAlertsDialogOpen({
  onClose,
  settings,
  variant,
}: NoiseAlertDialogProps) {
  const { langKeys, t } = useTranslate()

  const [showConfirm, setShowConfirm] = useState(false)
  const s = useNoiseAlertDialogSettings(settings, variant)

  const saveTrackingEvent = usePostTrackNoiseSettingsManageDialogSavedEvent()
  const cancelTrackingEvent =
    usePostTrackNoiseSettingsManageDialogCancelledEvent()

  function handleDiscardConfirmed() {
    cancelTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
    setShowConfirm(false)
    onClose()
  }

  const [editBlocked, setEditBlocked] = useState<{
    title: string
    body: string
  } | null>(null)

  const patchPreset = usePatchNoiseMonitoringPreset()
  const patchOrganization = usePatchOrganization()
  const callAssistState = useGetCallAssistActive()

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    saveTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })

    const presetMutation =
      s.isDirtyPreset() &&
      patchPreset.mutateAsync({
        body: s.profilePatchBody(),
        path: {
          organization_id: settings.orgId,
          profile_id: settings.profileId,
        },
      })

    const orgMutation =
      s.isDirtyOrg() &&
      patchOrganization.mutateAsync({
        orgId: settings.orgId,
        body: s.orgPatchBody(),
      })

    await Promise.all([presetMutation, orgMutation])
    onClose()
  }

  function handleClose() {
    if (s.isDirty()) {
      setShowConfirm(true)
    } else {
      onClose()
    }
  }

  const error =
    patchPreset.error || patchOrganization.error
      ? t(langKeys.failed_to_save)
      : undefined

  return (
    <MDialog
      title={title(t, variant)}
      description={t(langKeys.settings_noise_alerts_description)}
      confirmLabel={t(langKeys.save)}
      onClose={handleClose}
      loading={
        patchPreset.isLoading ||
        patchOrganization.isLoading ||
        callAssistState.isInitialLoading
      }
      error={error}
      open={true}
      hideClose
      formId={FORM_ID}
    >
      <MDialog
        title={editBlocked?.title}
        open={!!editBlocked}
        onClose={() => setEditBlocked(null)}
      >
        <MText>{editBlocked?.body}</MText>
      </MDialog>

      {showConfirm && (
        <DiscardChangesDialog
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false)
          }}
          onConfirm={handleDiscardConfirmed}
        />
      )}

      <Form id={FORM_ID} onSubmit={handleSubmit}>
        <section>
          <SectionSms
            variant={variant}
            smsEnabled={s.smsToGuest.value}
            onSetSmsEnabled={s.smsToGuest.setValue}
            message={s.smsMessage.value}
            setMessage={s.smsMessage.setValue}
          />
        </section>

        <section>
          <SectionAutocall
            autocallEnabled={s.autocall.value}
            toggleAutocallEnabled={s.autocall.setValue}
            autocallMessage={s.autocallMessage.value}
            onSetAutocallMessage={s.autocallMessage.setValue}
            callAssistEnabled={s.callAssistEnabled.value}
            signalEditBlocked={setEditBlocked}
            variant={variant}
          />
        </section>

        <section>
          <SectionFlashAndSound
            flashSoundEnabled={s.flashAndSound.value}
            toggleFlashSoundEnabled={s.flashAndSound.setValue}
            variant={variant}
          />
        </section>

        <Show if={variant === "third_alert"}>
          <section>
            <SectionCallAssist
              state={s.callAssistEnabled.value}
              setState={s.callAssistEnabled.setValue}
              autocallEnabled={s.autocall.value}
              canUseCallAssist={callAssistState.callAssistIsActive}
              signalEditBlocked={setEditBlocked}
              variant={variant}
            />
          </section>
        </Show>
      </Form>
    </MDialog>
  )
}

const Form = styled.form`
  display: grid;
  & > section:not(:last-child) {
    ${MDividerCss(spacing.L)};
  }
`

function title(t: TTranslateFunction, variant: TNoiseAlertVariant) {
  const titles: Record<TNoiseAlertVariant, string> = {
    first_alert: t(langKeys.settings_noise_alerts_first_alert_title),
    second_alert: t(langKeys.settings_noise_alerts_second_alert_title),
    third_alert: t(langKeys.settings_noise_alerts_third_alert_title),
  }

  return titles[variant]
}

function useNoiseAlertDialogSettings(
  initial: TNoiseAlertSettings,
  variant: TNoiseAlertVariant
) {
  const presetSettings = {
    smsToGuest: useIsValueDirty({ initial: initial.smsEnabled }),
    autocall: useIsValueDirty({ initial: initial.autocallEnabled }),
    flashAndSound: useIsValueDirty({ initial: initial.flashEnabled }),
    callAssistEnabled: useIsValueDirty({
      initial: initial.callAssistEnabled ?? false,
    }),
  } as const

  const orgSettings = {
    smsMessage: useIsValueDirty({ initial: initial.smsContent }),
    autocallMessage: useIsValueDirty({ initial: initial.autocallContent }),
  } as const

  const settings = { ...presetSettings, ...orgSettings } as const

  const isDirtyOrg = () => Object.values(orgSettings).some((x) => x.isDirty)
  const isDirtyPreset = () =>
    Object.values(presetSettings).some((x) => x.isDirty)
  const isDirty = () => Boolean(isDirtyOrg() || isDirtyPreset())

  return {
    ...settings,

    isDirty,
    isDirtyOrg,
    isDirtyPreset,

    profilePatchBody: () =>
      createProfileRequest(
        variant,
        settings.smsToGuest.value,
        settings.autocall.value,
        settings.flashAndSound.value,
        settings.callAssistEnabled.value
      ),
    orgPatchBody: () =>
      createOrganizationRequest(
        variant,
        settings.smsMessage.value,
        settings.autocallMessage.value
      ),
  }
}
